<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <!-- CARDS-->
    <b-row class="">
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="FlagIcon"
          color="warning"
          :statistic="total_gratis"
          statistic-title="Teste Grátis"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="info"
          :statistic="total_mensal"
          statistic-title="Mensal"
        >
        </statistic-card-horizontal>
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="AlertTriangleIcon"
          color="success"
          :statistic="total_anual"
          statistic-title="Anual"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="UsersIcon"
          color="primary"
          :statistic="total_assinantes"
          statistic-title="Total Assinantes"
        />
      </b-col>
    </b-row>

    <b-card>
      <FiltrosClientes/>
      <ListaClientes :clientes="clientes" @atualizar="buscaClientes"/>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BButton,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapState, mapGetters } from "vuex";
import FiltrosClientes from "./FiltrosClientes.vue"
import ListaClientes from './ListaClientes.vue';


export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    BButton,
    BRow,
    BCol,
    BCard,
    StatisticCardHorizontal,
    FiltrosClientes,
    ListaClientes,
   
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mqShallShowLeftSidebar: true,
    }
  },
  computed: {
    ...mapState({
        clientes: (state) => state.clientes.clientes,
        total_gratis: (state) => state.clientes.total_gratis,
        total_mensal: (state) => state.clientes.total_mensal,
        total_anual: (state) => state.clientes.total_anual,
        total_assinantes: (state) => state.clientes.total_assinantes,
    }),

    ...mapGetters({
     // receitasFiltradas: "receitas/filtradas",
      
    }),  
  },
  mounted() {
    this.buscaClientes();
  },
  methods: {
    buscaClientes() {
      this.$store.dispatch('clientes/listaClientes')
    },
  },
  filters: {
     formatMoeda(num) {
      if (!num) num = 0;
      return (
        "R$ " +
        parseFloat(num).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
