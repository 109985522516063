<template>
  <b-modal
      id="modal-editar-expiracao"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      size="md"
      title="Altera Data Expiração"
  >

    <b-form>

      <b-form-group label="Data de Expiração" label-for="data-expiracao-input">
        <b-form-datepicker
            id="compra"
            v-model="data_expiracao"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }"
            close-button
            hide-header
            label-close-button="Fechar"
            labelHelp=""
            :value="editarItem.data_expiracao"
        />
      </b-form-group>

      <b-form-group label="Código Google Authenticator" label-for="codigo-input">
        <cleave
            class="form-control"
            id="codigo-input"
            v-model="codigo"
            :options="option.cod"
            placeholder="000-000"
            required
        />
      </b-form-group>


      <b-form-group>
        <b-row >
          <b-col>
            <b-button
                block
                variant="gradient-success"
                @click="gravar"
            >
              <b-spinner
                  v-if="submit"
                  small
                  type="grow"
                  class="mr-2"
              /> Gravar
            </b-button>
          </b-col>
          <b-col>
            <b-button
                block
                :disabled="submit"
                variant="secondary"
                @click="$bvModal.hide('modal-editar-expiracao')"
            >
              Fechar
            </b-button>
          </b-col>

        </b-row>
      </b-form-group>
    </b-form>

  </b-modal>
</template>

<script>
import {
  BModal,
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker, BSpinner,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component/src/component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {mapState} from "vuex";
export default {
  components: {
    BSpinner,
    Cleave,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BForm,
    BModal,
    BTable,
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
  },
  props: ['editarItem'],

  data() {
    return {
      submit: false,
      data_expiracao: this.editarItem.data_expiracao || '',
      codigo: '',
      option: {
        cod: {
          blocks: [3, 3],
          delimiter: '-',
          numericOnly: true,
        },
      }
    };
  },

  computed: {
    ...mapState({
      user: state => state.user.user
    }),
  },

  watch: {
    editarItem: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.data_expiracao) {
          this.data_expiracao = newVal.data_expiracao;
        }
      }
    }
  },

  methods: {
    gravar() {

      this.submit = true


      if (this.codigo.length < 6) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Validação",
            text: "O código deve ter no mínimo 6 dígitos.",
            variant: "warning",
          }
        });
        this.submit = false;
        return;
      }


      const params = {
        data_expiracao: this.data_expiracao,
        codigo: this.codigo,
        user_id: this.user.id
      }

      this.$http
          .put("adm/administracao/clientes/altera-expiracao/" + this.editarItem.id_company, params)
          .then((res) => {

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Data Expiração",
                text: "Data de Expiração Alterada com Sucesso!",
                variant: "success",
                icon: "AlertIcon",
              }
            })

            this.$bvModal.hide('modal-editar-expiracao')
            this.$emit('atualizar')

          }).catch((err) => {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data Expiração",
            text: err.response.data.message,
            variant: "danger",
            icon: "AlertIcon",
          }
        })
      }).finally( () => {
        this.submit = false
      })

    }
  }

};
</script>

<style>
</style>